import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { ConfirmationStepProps } from "../../../types";
import NavigationButtons from "../../NavigationButtons";
import useAppContext from "../../../../../../useAppContext";
import { CreateInvoiceWebPayload } from "../../../../../../api/invoice/types";
import api from "../../../../../../api";
import { useSelectedStoreOptional } from "../../../../../SelectedStore/context";
import changePage from "../../../../../../helpers/changePage";
import { Alert, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import AgreementCheckbox from "../../../../../../auth/Autorisation/inputs/AgreementCheckbox";
import useSearchParamsFixed from "../../../../../../features/hooks/useSearchParamsFixed";
import Interweave from "../../../../../../features/Interweave";
import { CheckItemType } from "../../../../../../features/Check/types";
import { Check } from "../../../../../../features/Check";
import useExtraFee from "../../../../hooks/useExtraFee";

export default function ConfirmationStep(props: ConfirmationStepProps) {
	const {
		brandInfo,
		localisation,
		authService: { user },
		showError,
	} = useAppContext();
	const [searchParams] = useSearchParamsFixed();

	const selectedStore = useSelectedStoreOptional();

	const [isLoading, setIsLoading] = useState(false);
	const [isOfferAccepted, setIsOfferAccepted] = useState(false);

	const { processedCheck } = props.incustService;

	const calculatedItems = useMemo(() => {
		const items: CheckItemType[] = [];

		if (props.invoiceTemplate?.items.length) {
			const count = props.form.count || 1;

			props.invoiceTemplate.items.forEach(item => {
				const quantity = item.quantity * count;
				const itemSum = item.price * quantity;
				items.push({
					name: item.name,
					quantity: quantity,
					price: item.price,
					sum: itemSum,
					hideImage: true,
				});
			});
		} else if (props.form.entered_amount) {
			items.push({
				name: localisation.payment.enteredAmountItemName,
				quantity: 1,
				hideQuantity1: true,
				price: props.form.entered_amount,
				sum: props.form.entered_amount,
				hideImage: true,
			});
		}

		return items;
	}, [
		props.invoiceTemplate?.items,
		props.form.entered_amount,
		props.form.count,
		localisation.payment.enteredAmountItemName,
	]);

	const { makePrePayment } = props.paymentService;
	const onSubmit = useCallback(
		async (e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			if (props.invoiceCreated) {
				if (
					props.paymentService.paymentsInfo?.single_payment_method?.provider ===
						"incust_pay" ||
					props.paymentService.paymentsInfo?.single_payment_method?.provider ===
						"friend" ||
					props.paymentService.paymentsInfo?.single_payment_method?.provider ===
						"orange" ||
					props.paymentService.forceSingleMethod?.provider === "incust_pay" ||
					props.paymentService.forceSingleMethod?.provider === "friend" ||
					props.paymentService.forceSingleMethod?.provider === "orange"
				) {
					props.setShowPayments(true);
					return;
				}

				if (
					(props.paymentService.paymentsInfo?.single_payment_method?.is_online ||
						props.paymentService.forceSingleMethod?.is_online) &&
					props.paymentService.paymentsInfo?.single_payment_method?.need_comment !==
						"required" &&
					props.paymentService.forceSingleMethod?.need_comment !== "required" &&
					props.paymentService.paymentsInfo?.single_payment_method?.need_comment !==
						"optional" &&
					props.paymentService.forceSingleMethod?.need_comment !== "optional"
				) {
					try {
						await makePrePayment(
							false,
							props.invoiceCreated.invoice.id,
							props.invoiceCreated.token_data.token
						);
						return;
					} catch (err) {
						console.log(err);
					}
				}

				return props.setShowPayments(true);
			}

			if (!brandInfo) return;

			try {
				setIsLoading(true);
				const args: CreateInvoiceWebPayload = {
					group_id: brandInfo.group_id,
					payment_mode: props.paymentMode,
					email: props.form.email,
					phone: props.form.phone,
					first_name: props.form.first_name,
					last_name: props.form.last_name,
					is_accepted_agreement: props.form.is_accepted_agreement,
					menu_in_store_id: props.menuInStoreId,
					store_id: selectedStore?.id,
					invoice_template_id: props.invoiceTemplate?.id,
					entered_amount: props.form.entered_amount,
					count: props.form.count,
					with_url: false,
					successful_payment_callback_url: window.location.href,
					client_redirect_url: window.location.href,
					incust_check: props.incustService.processedCheck,
					user_comment: props.form.user_comment,
					external_transaction_id: props.externalId,
				};
				const response = await api.invoice.createInvoice(args);
				props.setErrorText("");

				const currentLastPersonalData = api.shop.order.getLastPersonalData() || {};
				api.shop.order.saveLastPersonalData(
					JSON.stringify({
						...currentLastPersonalData,
						email: props.form.email,
						phone: props.form.phone,
						first_name: props.form.first_name,
						last_name: props.form.last_name,
					})
				);

				console.log("*** INVOICE RESPONSE", response.data);

				if (response.data.invoice.status === "payed") {
					changePage(`/shop/invoice/${response.data.invoice.id}`, {
						invoice_token: response.data.token_data.token,
						mode: null,
						entered_amount: null,
						invoice_template_id: null,
					});
				} else {
					props.setInvoiceCreated(response.data);
					props.onInvoiceCreated && props.onInvoiceCreated(response.data);

					if (
						props.paymentService.paymentsInfo?.single_payment_method?.provider ===
							"incust_pay" ||
						props.paymentService.paymentsInfo?.single_payment_method?.provider ===
							"friend" ||
						props.paymentService.paymentsInfo?.single_payment_method?.provider ===
							"orange" ||
						props.paymentService.forceSingleMethod?.provider === "incust_pay" ||
						props.paymentService.forceSingleMethod?.provider === "friend" ||
						props.paymentService.forceSingleMethod?.provider === "orange"
					) {
						props.setShowPayments(true);
						return;
					}

					if (
						(props.paymentService.paymentsInfo?.single_payment_method?.is_online ||
							props.paymentService.forceSingleMethod?.is_online) &&
						props.paymentService.paymentsInfo?.single_payment_method?.need_comment !==
							"required" &&
						props.paymentService.forceSingleMethod?.need_comment !== "required" &&
						props.paymentService.paymentsInfo?.single_payment_method?.need_comment !==
							"optional" &&
						props.paymentService.forceSingleMethod?.need_comment !== "optional"
					) {
						try {
							await makePrePayment(
								false,
								response.data.invoice.id,
								response.data.token_data.token
							);
							return;
						} catch (err) {
							console.log(err);
						}
					}
					props.setShowPayments(true);
				}
			} catch (ex: any) {
				if (ex?.response?.status === 409) {
					showError(ex, true);
					return;
				}
				props.setErrorText(ex?.response?.data?.detail || localisation.global.errApi);
			} finally {
				props.incustService.clearLoyaltyData();
				setIsLoading(false);
			}
		},
		[brandInfo, localisation.global.errApi, makePrePayment, props, selectedStore?.id, showError]
	);

	const isOfferEnabled = brandInfo?.is_offer_doc_exist;

	const subtotal = calculatedItems.reduce((acc, cur) => acc + cur.sum, 0);

	const totalDiscount = processedCheck
		? (processedCheck.discount_amount || 0) + (processedCheck.bonuses_redeemed_amount || 0)
		: 0;
	const totalAmount = subtotal - totalDiscount;
	const extraFeeService = useExtraFee(
		{ totalSum: totalAmount, sumToPay: totalAmount },
		selectedStore?.id,
		selectedStore?.currency || props.currency
	);

	useEffect(() => {
		if (totalAmount === 0) return;
		props.paymentService.setSums({
			totalSum: extraFeeService.calcTotalSumWithExtraFee || totalAmount,
			sumToPay: extraFeeService.calcTotalSumWithExtraFee || totalAmount,
		});
	}, [
		totalAmount,
		props.paymentService.calcSumToPay,
		props.paymentService,
		extraFeeService.calcTotalSumWithExtraFee,
	]);

	const setForceSingleMethod = props.paymentService.setForceSingleMethod;
	useEffect(() => {
		let methods = props.paymentService.methods.filter(method => method.is_online);
		if (!user) {
			methods = methods.filter(method => method.provider !== "friend");
		}
		if (methods.length === 1) {
			if (methods[0].is_online) setForceSingleMethod(methods[0]);
		}
	}, [props.paymentService.methods, setForceSingleMethod, user]);

	return (
		<form onSubmit={onSubmit}>
			<Typography variant={"h6"} fontWeight={"600"}>
				{localisation.payment.confirmationStepCheckHeaderText}
			</Typography>
			<Check
				data={{
					type: "regular",
					viewOrder: true,
					toName: `${props.form.first_name} ${props.form.last_name}`,
					email: props.form.email,
					phone: props.form.phone,
					comment: props.form.user_comment,
					items: calculatedItems,
					subtotal: subtotal,
					totalDiscount: totalDiscount || null,
					totalAmount: totalAmount,
					totalAmountWithTips: totalAmount,
					payer_fee: props.paymentService.calculateFeeRange(),
					payer_fee_row: props.paymentService.showPayerFeeRow,
					sum_to_pay: props.paymentService.calculateFeeRange(true),
					extraFee: extraFeeService.extraFees,
					total_sum_with_extra_fee: extraFeeService.calcTotalSumWithExtraFee,
					isLoyaltyAwards: !!(
						processedCheck?.bonuses_added_amount ||
						processedCheck?.emitted_coupons?.length ||
						processedCheck?.special_accounts_charges?.length
					),
					bonuses: processedCheck?.bonuses_added_amount,
					couponsCount: processedCheck?.emitted_coupons?.length,
					customerAccounts: processedCheck?.special_accounts_charges,
				}}
				itemsHeaderProps={{
					lineHeight: 1.2,
				}}
			/>

			{props.errorText && (
				<Alert severity={"error"} variant={"filled"}>
					<Interweave content={props.errorText} />
				</Alert>
			)}

			{(!props.invoiceTemplate || props.invoiceTemplate.comment_mode !== "disabled") && (
				<TextField
					multiline
					maxRows={10}
					size={"small"}
					fullWidth
					required={props.invoiceTemplate?.comment_mode === "required"}
					label={
						props.invoiceTemplate?.comment_label || localisation.payment.commentLabel
					}
					value={props.form.user_comment || ""}
					onChange={e => props.setForm({ user_comment: e.target.value })}
					sx={{ mt: 4 }}
				/>
			)}

			{!props.invoiceCreated && (
				<>
					{(!user || !user?.is_accepted_agreement) && (
						<AgreementCheckbox
							value={props.form.is_accepted_agreement || false}
							setValue={newValue =>
								props.setForm({ is_accepted_agreement: newValue })
							}
						/>
					)}

					{isOfferEnabled && (
						<FormControlLabel
							sx={{ mt: 1 }}
							control={
								<Checkbox
									name={"Agreement"}
									checked={isOfferAccepted}
									onChange={e => {
										setIsOfferAccepted(e.target.checked);
									}}
								/>
							}
							label={
								<Interweave
									content={localisation.orders.offerAgreementText.replace(
										"{offer_link}",
										`/shop/document/agreement/?${searchParams.toString()}`
									)}
								/>
							}
						/>
					)}
				</>
			)}

			<NavigationButtons
				loadingNext={isLoading}
				onPrevClick={props.setPrevStep}
				disabledPrev={!!props.invoiceCreated}
				disabledNext={
					(isOfferEnabled && !isOfferAccepted) ||
					(user && user.email && !user.is_confirmed_email) ||
					(!user?.is_accepted_agreement && !props.form.is_accepted_agreement) ||
					!(props.invoiceTemplate?.items.length || props.form.entered_amount) ||
					props.paymentService.isLoading
				}
				nextButtonNode={
					props.form.payment_method === "online" &&
					props.paymentService.paymentsInfo?.is_multiple_payment_methods
						? localisation.orders.payButton
						: localisation.global.confirmButton
				}
			/>
		</form>
	);
}
